<!--
 * @Descripttion: 特有样式按钮组件
 * @version: 0.1
 * @Author: luoxi
 * @Date: 2020年3月18日10:52:9
 * @LastEditors: luoxi
 * @LastEditTime: 2020年3月19日10:52:15
 -->
<template>
  <div class="circle-radio">
    <button
      class="radio-button"
      v-for="(item, key) in options"
      :key="key"
      :class="{ 'active': active === item[props.id] }"
      @click="activeChange(item, $event)"
    >
      {{ item[props.label] }}
    </button>
    <div class="radio-button active slider-button" :style="{ 'width': this.dom.width, 'left': this.dom.left }"></div>
  </div>
</template>

<script>
  export default {
    components: {

    },
    model: {
      prop: 'choose',
      event: 'chooseEvent'
    },
    props: {
      // 列表数据
      options: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 参数
      props: {
        type: Object,
        default: () => {
          return {
            id: 'id',
            label: 'label'
          }
        }
      },
      // 已选项，供v-model使用，支持两种格式：字符类型/数字类型
      choose: {
        type: [String, Number],
        default: ''
      }
    },
    data() {
      return {
        active: '',
        dom: {
          width: '30px',
          left: '0px'
        }
      }
    },
    watch: {
      active(newVal) {
        this.$emit('chooseEvent', newVal)
      },
      choose(newVal) {
        this.active = newVal
      }
    },
    created() {

    },
    mounted() {
      this.initActive()
      this.active = this.choose
    },
    methods: {
      // 初始化
      initActive() {
        let btn = document.querySelector('.radio-button')
        this.dom = {
          width: btn.offsetWidth + 'px',
          left: btn.offsetLeft + 'px'
        }
      },
      // 改变选中项
      activeChange(item, e) {
        this.active = item[this.props.id]
        let btn = e.srcElement
        this.dom = {
          width: btn.offsetWidth + 'px',
          left: btn.offsetLeft + 'px',
        }
        
      }
    }
  }
</script>

<style scoped="" lang="scss">
  .circle-radio{
    background:rgba(114,172,254,1);
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .radio-button{
      z-index: 10;
      padding: 9px 30px;
      border-radius: 18px;
      border: 0px;
      outline: none;
      transition: 0.3s;
      cursor: pointer;
      background: transparent;
      font-size: 1rem;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,1);
      &:first-child{
        margin-left: 3px;
      }
      &:last-child{
        margin-right: 3px;
      }
      &:hover{
        // color: #5295f3;
      }
      &.active{
        background: transparent;
      }
      &.slider-button{
        position: absolute;
        z-index: 9;
        left: 2px;
        top: 1px;
        height: 18px;
        width: 30px;
        background: #3489FE;
        padding-left: 0;
        padding-right: 0;
        transition: 0.3s;
      }
    }
  }
</style>
